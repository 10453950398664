<div>
    <h1>Look up graves on Find A Grave&trade:</h1>
    <a href="https://www.findagrave.com/cemetery/907129/white-oak-cemetery">
        <img src="findagrave.png" alt="Find a Grave">
    </a>
</div>

<style>
    div, a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div {
        height: 15rem;
        background-color: rgb(188, 202, 208);
        color: rgb(0, 0, 0);
        
        justify-content: space-evenly;
    }

    a {
        background-color: #5c60a3;

        height: 4rem;
        width: 15rem;

        border-radius: 2rem;

        justify-content: center;

        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);

        transition: all 250ms;
    }

    a:hover {
        background-color: #6a6fbe;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    img {
        height: 70%;
    }
</style>