<script>
    import { fly } from 'svelte/transition';
    import { circIn, circOut } from 'svelte/easing';
</script>

<header in:fly="{{ y: -200, duration: 500, easing: circOut }}" out:fly="{{ y: -200, duration: 500, easing: circIn }}">
    <object id="topBar" type="image/svg+xml" data="/whiteOakLong.svg" aria-label="White Oak Cemetery"></object>
</header>

<style>
    header {
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        height: 10vh;

        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;

        z-index: 9999;
    }

    object {
        max-width: 90%;
        height: 80%;
    }
</style>