<footer>
    <p>Copyright White Oak Cemetery, {new Date().getFullYear()}</p>
</footer>

<style>
    footer {
        background-color: rgb(0, 0, 0);

        height: 3rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        color: rgb(255, 255, 255);
    }
</style>