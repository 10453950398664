<div id="wrapper">
    <div id="textWrapper">
        <h1>Visit</h1>
        <p>The cemetery is located at<br>77 Delzell Woods Road,<br>Rogersville, Missouri 65742.</p>
    </div>
    <iframe title="Cemetery Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3181.019705574915!2d-93.02682428430452!3d37.128446206852146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87cf8378f1fed585%3A0x164ad8c9806f1ed1!2sWhite%20Oak%20Cemetery%2C%2077%20Delzell%20Woods%20Rd%2C%20Rogersville%2C%20MO%2065742!5e0!3m2!1sen!2sus!4v1642376228592!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
</div>

<style>
    #wrapper {
        background-color: rgb(73, 80, 84);
        color: rgb(255, 255, 255);

        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

    #textWrapper {
        width: 40%;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    iframe {
        width: 60%;
    }

    p {
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 800px) {
        #wrapper {
            flex-direction: column;
        }

        #textWrapper {
            width: 90%;
        }

        iframe {
            width: 100%;
        }
    }
</style>