<div id="wrapper">
    <h1>Trustees</h1>
    <div id="titleNames">
        <div class="titleWrapper">
            <div class="phoneWrapper">
                <h5>John Kensinger</h5>
                <p class="phone">(417) 830-2197</p>
            </div>
            <p>President</p>
        </div>
        <div class="titleWrapper">
            <div class="phoneWrapper">
                <h5>Scott Bass</h5>
                <p class="phone">(417) 839-3791</p>
            </div>
            <p>Vice President</p>
        </div>
        <div class="titleWrapper">
            <div class="phoneWrapper">
                <h5>M. Darin Wray</h5>
                <p class="phone">(417) 830-4274</p>
            </div>
            <p>Secretary, Treasurer</p>
        </div>
    </div>
    <div id="restNames">
        <div class="phoneWrapper">
            <h5>Bill Green</h5>
            <p class="phone">(417) 839-8476</p>
        </div>
        <div class="phoneWrapper">
            <h5>Kevin Bass</h5>
            <p class="phone">(417) 844-0346</p>
        </div>
        <div class="phoneWrapper">
            <h5>Keith Jean</h5>
            <p class="phone">(417) 839-4728</p>
        </div>
        <div class="phoneWrapper">
            <h5>Chase T. Stone</h5>
            <p class="phone">(417) 844-5693</p>
        </div>
        <div class="phoneWrapper">
            <h5>Bill Stoner</h5>
            <p class="phone">(417) 818-2986</p>
        </div>
        <div class="phoneWrapper">
            <h5>Dan Hintz</h5>
            <p class="phone">(417) 839-3796</p>
        </div>
    </div>
</div>

<style>
    #wrapper {
        background-color: rgba(112, 122, 126, 1);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    #titleNames, #restNames {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        width: 90%;

        margin: 2rem 0 2rem 0;
    }

    .phoneWrapper {
        margin: 1rem;
    }

    h1, h5, p {
        color: rgba(255, 255, 255, 1);
    }

    h1 {
        font-size: 3rem;
    }

    h5 {
        font-size: 1.5rem;
        margin: 0.75rem;
        padding: 0;
    }

    p {
        font-style: italic;
        font-size: 1rem;
        margin-top: 1rem;
    }
</style>