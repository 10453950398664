<script>
	import Header from './Header.svelte';
	import Landing from './Landing.svelte';
	import Trustees from './Trustees.svelte';
	import Donations from './Donations.svelte';
	import Visit from './Visit.svelte';
	import FindAGrave from './FindAGrave.svelte';
	import Footer from './Footer.svelte';


	let viewportHeight = window.innerHeight;
	let scroll = window.scrollY;

	window.onresize = () => {
		viewportHeight = window.innerHeight;
	}

	document.addEventListener('scroll', _ => {
		scroll = window.scrollY;
	});
</script>

<main>
	{#if scroll >= viewportHeight * 0.6}
		<Header/>
	{/if}
	<Landing/>
	<Trustees/>
	<Donations/>
	<Visit/>
	<FindAGrave/>
	<Footer/>
</main>

<style>
	main {
		text-align: center;
		width: 100%;
		padding: 0;
		margin: 0;
	}
</style>