<script>
    import { fade, scale } from 'svelte/transition';
	import { sineInOut } from 'svelte/easing';
import { onMount } from 'svelte';

    let showScrollHint = false;
	let showLogo = false;

	onMount(() => {
		showLogo = true;
	});

    setTimeout(() => {
        showScrollHint = true;
    }, 1000);
</script>

<div id="landing">
	{#if showLogo}
    <object in:scale="{{duration: 2000, start: 0.9, opacity: 0.5, easing: sineInOut}}" type="image/svg+xml" data="/whiteOakWhite.svg" aria-label="White Oak Cemetery"></object>
	{/if}
    {#if showScrollHint}
	<div id="scrollHint" transition:fade="{{ duration: 2000, easing: sineInOut }}">
		<p>scroll</p>
		<svg viewBox="0 0 100 25">
			<line x1="0" y1="0" x2="50" y2="25" style="stroke:rgba(255,255,255,1);stroke-width:2;stroke-linecap:round"/>
			<line x1="50" y1="25" x2="100" y2="0" style="stroke:rgba(255,255,255,1);stroke-width:2;stroke-linecap:round"/>
		</svg>
	</div>
    {/if}
</div>

<style>
    #landing {
		width: 100%;
		height: 100vh;

		background-image: url('/gateWithFlag.jpg');
		background-attachment: fixed;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	object {
		width: 70vw;
		max-height: 30vh;
		max-width: 70vw;
		margin: auto;
	}

	#scrollHint {
		width: max-content;
		height: max-content;
		position: absolute;
		top: 90vh;

		color: rgba(255, 255, 255, 1);
	}

	p {
		font-style: italic;
		margin: auto;
	}

	svg {
		height: 1rem;
	}
</style>