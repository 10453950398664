<div id="wrapper">
    <div id="textWrapper">
        <h1>Donations</h1>
        <p>White Oak Cemetery is a non-endowed cemetery. It is maintained solely by donations and interest on funds left to it by will and sale of lots.</p>
        <p>Your consideration of an annual donation or a bequeath will greatly aid in continual upkeep. Please contact any of our trustees to donate.</p>
    </div>
    <img src="/gazebo.jpg" alt="Cemetery in snow">
</div>

<style>
    #wrapper {
        background-color: rgb(188, 202, 208);
        color: rgb(0, 0, 0);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    #textWrapper {
        width: 50%;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    p {
        width: 90%;
        max-width: 30rem;
    }

    img {
        height: 100%;
        width: 50%;
    }

    @media only screen and (max-width: 800px) {
        #wrapper {
            flex-direction: column;
        }

        #textWrapper {
            width: 90%;
        }

        p:last-child {
            margin-bottom: 2rem;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
</style>